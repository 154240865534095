<template>
  <v-dialog
    :value="true"
    max-width="400px"
    @click:outside="close"
  >
    <v-card>
      <v-img :src="imageURL" />
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      <v-card-text v-html="message" />
      <v-card-actions>
        <v-btn
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'NoticeDialog',
    props: {
      imageURL: {
        type: String,
        default: ''
      }, 
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      }, 
      message: {
        type: String, 
        default: ''
      } 
    },
    methods: {
        close() {
            this.$emit('closed')
        }
    }
}
</script>